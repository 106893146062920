.Privacy {
  h2 {
    font-size: 22px;
    line-height: 1.2em;
    margin-bottom: 40px;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
    }
  }

  .privacy-section-heading {
    font-weight: bold;
  }
}
