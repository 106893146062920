@import "assets/scss/sass";

$logo-size: 40px;

.App {
    @extend %layout-side-pad;
    max-width: 800px;
    // padding-top: 50px;
    // padding-bottom: 50px;
    margin: 0 auto;

    header {
        display: flex;
        align-items: "center";
        padding: 30px 0;
        border-bottom: 1px solid rgba($color-blue, 0.5);
        margin-bottom: 50px;
        .logo {
            display: flex;
            align-self: "center";
            // width: 50%;
            img {
                display: inline-block;
                width: $logo-size;
                height: $logo-size;
                // vertical-align: top;
                margin-right: 12px;
            }
            h1 {
                display: inline-block;
                font-size: 24px;
                font-weight: bold;
                line-height: $logo-size;
                padding: 0;
                margin: 0;
                a {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }
        // .nav {
        //     width: 50%;
        //     text-align: right;
        //     ul {
        //         list-style-type: none;
        //         line-height: $logo-size;
        //         li {
        //             display: inline;
        //             &:not(:last-child) {
        //                 margin-right: 20px;
        //             }
        //         }
        //     }
        // }
    }

    footer {
        font-size: 16px;
        line-height: 16px;
        text-align: left;
        padding: 30px 0;
        border-top: 1px solid rgba($color-blue, 0.5);
        margin-top: 50px;

        ul {
            list-style-type: none;
            // line-height: 50px;
            li {
                display: block;
                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                @include respond-to($bp-md) {
                    display: inline-block;
                    &:not(:last-child) {
                        margin-right: 20px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
