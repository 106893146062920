@import "../../config/breakpoints//breakpoints";

%layout-width-wide {
  max-width: 900px;
  margin: 0 auto;
}

%layout-width-medium {
  max-width: 700px;
  margin: 0 auto;
}

%layout-width-narrow {
  max-width: 500px;
  margin: 0 auto;
}

%layout-side-pad {
  $padding-x: (
    bp-xs: 40px,
    bp-sm: 60px,
  );
  @include responsive-values("padding-left", $padding-x);
  @include responsive-values("padding-right", $padding-x);
}
