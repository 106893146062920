
@mixin respond-to($bp) {
  @if $bp == "0px" {
    @content;
  } @else {
    @media (min-width: $bp) {
      @content;
    }
  }
}

@function get-responsive-value($map, $key) {
  @if map-has-key($map, $key) {
    @return map-get($map, $key);
  }
  // @warn "Unknown key `#{$key}` in map. `#{$map}`";
  @return null;
}

@mixin responsive-values($property, $map) {
  @each $breakpoint-name, $breakpoint in $breakpoints {
    @include respond-to($breakpoint) {
      #{$property}: get-responsive-value($map, $breakpoint-name);
    }
  }
}

/*

Usage:
Note - each breakpoint is optional.

$some-font-sizes: (
  bp-xs: 30px,
  bp-sm: 40px,
  bp-xl: 70px
);

p {
  @include responsive-values('font-size', $some-font-sizes);
}

*/
