
@import 'assets/scss/sass';

body {
  color: $color-white;
  background: $color-black;
  font-size: 18px;
  line-height: 1em;
  font-family: 
  // -apple-system, system-ui, BlinkMacSystemFont, 
  'Segoe UI', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  border: 0;
  margin: 0;
}

p, ul {
  line-height: 1.25em;
  margin-bottom: 20px;
}

a {
  color: $color-blue;
}

ul, ul {
  padding: 0;
  margin: 0;
}